import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/Featured/SEO";
import { Container, RoadmapCard } from "../components/Shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const query = graphql`
  {
    data: roadmapJson {
      title
      description
      cards {
        title
        items
      }
    }
  }
`;

function partition(arr, length) {
  const res = [];
  for (let i = 0; i < arr.length; i += length) {
    const chunk = arr.slice(i, i + length);
    res.push(chunk);
  }
  return res;
}

function IndexPage() {
  const { data } = useStaticQuery(query);
  const { cards, title, description } = data;

  return (
    <div className="space-y-44 py-44">
      <SEO />
      <section>
        <Container>
          <div className="space-y-16">
            <div className="text-center space-y-10">
              <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                {title}
              </h2>
              <p className="text-base md:text-lg text-white font-sans mx-auto md:max-w-[90%] lg:max-w-[80%]">
                {description}
              </p>
            </div>
            <div>
              {Object.keys(partition(cards, 3)).map((key) => (
                <div
                  className={classNames(
                    "grid grid-cols-1 lg:grid-cols-3 lg:relative gap-6 lg:px-6",
                    parseInt(key, 10) < partition(cards, 3).length && "pb-6"
                  )}
                  key={key}
                >
                  {Object.keys(partition(cards, 3)[key]).map((keyi, i) => (
                    <div key={keyi} className="flex lg:flex-col gap-4">
                      <div className="relative lg:flex justify-center">
                        <svg
                          className="flex-shrink-0 w-6 h-6 z-[1]"
                          viewBox="0 0 792 792"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="99.0145"
                            y="396"
                            width="420"
                            height="420"
                            transform="rotate(-45 99.0145 396)"
                            fill="#0F172A"
                            stroke="url(#paint0_linear_129_3)"
                            strokeWidth="140"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_129_3"
                              x1="280.02"
                              y1="396"
                              x2="280.02"
                              y2="956"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#ff5b5f" />
                              <stop offset="1" stopColor="#ff141b" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div className="lg:hidden absolute top-6 left-1/2 -translate-x-1/2 bg-third w-[.125rem] h-full" />
                        <div
                          className={classNames(
                            "hidden lg:block absolute top-1/2 left-0 -translate-y-1/2 bg-third w-[calc(100%_+_1.5rem)] h-[.125rem]",
                            i === 0 && "-left-6 w-[calc(100%_+_3rem)]"
                          )}
                        />
                      </div>
                      <RoadmapCard data={partition(cards, 3)[key][keyi]} />
                    </div>
                  ))}
                  {parseInt(key, 10) + 1 < partition(cards, 3).length && (
                    <div
                      className={classNames(
                        "absolute top-3 bottom-0 bg-third w-[.125rem] h-full hidden lg:block",
                        parseInt(key, 10) % 2 !== 0 ? "left-0" : "right-0"
                      )}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default IndexPage;
